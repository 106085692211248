import * as React from "react"
import styled from "styled-components"
import "@fontsource/merriweather"
import "@fontsource/staatliches"
import PortfolioCard from "../../components/portfolio-card";
import {graphql, Link} from 'gatsby'
import SHLHeader from "../../components/SHL-header";

// styles
const pageStyles = {
  fontFamily: "Roboto, sans-serif, serif",
  width: "auto",
  height: "auto",
  minHeight: "100vh",
  padding: "auto",
}
const contentStyles = {
  maxWidth: "768px",
  margin: "auto",
  padding: "16px",
  color: "#d8d8d8",
  backgroundColor: "#181818cc",
  marginBottom: "16px",
  borderRadius: "8px"
}
const Intro = styled.p`
  font-family: Merriweather, serif;
  font-size: 1.1em;
  a {
    color: #ff008c;
    transition: color 0.25s;
    height: 100%;
    :hover {
      color: white;
    }
  }
`
const FullContentWrapper = styled.div`
  animation: 0.25s ease-out 0s 1 FadeInDown;
`

// markup
const PortfolioIndexPage = ({data}) => {
  return (
      <main style={pageStyles}>
        <div style={contentStyles}>
            <title>Portfolio | SH Liu | rarakasm</title>
            <SHLHeader subtitle="Portfolio"/>
            <FullContentWrapper>
                <Intro>
                    If you'd like to know further details not presented here, <Link to="/about/">feel free to contact me</Link>.
                </Intro>
                <div>
                    {
                        data.allMarkdownRemark.nodes.map((node) =>
                            <PortfolioCard data={node}/>
                        )
                    }
                </div>
            </FullContentWrapper>
        </div>
      </main>
  )
}

export default PortfolioIndexPage

export const query = graphql`
    query GetAllProjects {
      allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: {fileAbsolutePath: {regex: "/(project)/"}}) {
        nodes {
          html
          frontmatter {
            title
            path
            badge
            summary
            date(formatString: "YYYY")
            thumbnail
            tags
          }
        }
      }
    }
`
